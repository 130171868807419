import { StaticButterbarComponent } from 'gamma_components/Butterbar/Butterbar'
import { useFeatureFlag } from 'modules/featureFlags'

export const OutageButterbar = () => {
  const butterbarConfig = useFeatureFlag('outageButterbar')
  if (!butterbarConfig.enabled) return null

  const { header, text, buttonLink, buttonText, key } = butterbarConfig

  return (
    <StaticButterbarComponent
      definition={{
        key,
        header,
        text,
        buttonText,
        buttonLink,
      }}
    />
  )
}
